import React from "react"
import "../styles/styles.scss"
import SEO from "../components/seo"
import Header from "./../components/header"
import Footer from "./../components/footer"
import Copy from "./../components/copy"
import { Link } from "gatsby"

const PriceListPage = () => {
  if (typeof window !== "undefined") {
    require("smooth-scroll")(".smooth-scroll", {
      updateURL: false,
      offset: () => {
        const header = document.getElementById("page__header")
        return header.offsetHeight
      },
    })
  }

  return (
    <div className="page-pricelist">
      <SEO title="Ceník | BEKAP s.r.o. | Postaráme se o Vaše účetnictví" />
      <Header />
      <div className="pricelist__hero">
        <h2>Ceník služeb</h2>
      </div>
      <div className="page__content">
        <div className="container">
          <div className="content">
            <table className="pricelist">
              <tbody>
                <tr>
                  <td>
                    Podvojné účetnictví - plátci DPH{" "}
                    <br className="hidden--landscape" />
                    <small>(30 položek v ceně)</small>
                  </td>
                  <td>
                    1800 Kč <small>/měsíc</small>
                  </td>
                </tr>
                <tr>
                  <td>
                    Daňová evidence - plátci DPH{" "}
                    <br className="hidden--landscape" />
                    <small>(30 položek v ceně)</small>
                  </td>
                  <td>
                    1000 Kč <small>/měsíc</small>
                  </td>
                </tr>
                <tr>
                  <td>
                    Podvojné účetnictví - neplátci DPH{" "}
                    <br className="hidden--landscape" />
                    <small>(30 položek v ceně)</small>
                  </td>
                  <td>
                    1800 Kč <small>/měsíc</small>
                  </td>
                </tr>
                <tr>
                  <td>
                    Daňová evidence - neplátci DPH{" "}
                    <br className="hidden--landscape" />
                    <small>(30 položek v ceně)</small>
                  </td>
                  <td>
                    1000 Kč <small>/měsíc</small>
                  </td>
                </tr>
                <tr>
                  <td>Účetní řádek</td>
                  <td>20 Kč</td>
                </tr>
                <tr>
                  <td>Položka peněžního deníku</td>
                  <td>
                    13 Kč <small>/ks</small>
                  </td>
                </tr>
                <tr>
                  <td>
                    Zpracování daňového přiznání k{" "}
                    <br className="hidden--landscape" />
                    dani z příjmu{" "}
                    <small>
                      (DPPO) včetně založení do{" "}
                      <br className="hidden--landscape" />
                      Sbírky listin u OR
                    </small>
                  </td>
                  <td>3500 Kč</td>
                </tr>
                <tr>
                  <td>
                    Zpracování daňového přiznání k dani{" "}
                    <br className="hidden--landscape" />z příjmu pro OSVČ a
                    přehledů pro <br className="hidden--landscape" />
                    ČSSZ a ZP
                  </td>
                  <td>
                    1500 Kč <small>/měsíc</small>
                  </td>
                </tr>
                <tr>
                  <td>Mzdy - zpracování 1 zaměstnance měsíčně</td>
                  <td>280 Kč</td>
                </tr>
                <tr>
                  <td>Zpracování daně z příjmu ze závislé činnosti</td>
                  <td>300 Kč</td>
                </tr>
                <tr>
                  <td>Roční zúčtování zaměstnance</td>
                  <td>250 Kč</td>
                </tr>
                <tr>
                  <td>
                    Nástup, výstup zaměstnance{" "}
                    <br className="hidden--landscape" />
                    <small>za zaměstnance</small>
                  </td>
                  <td>250 Kč</td>
                </tr>
                <tr>
                  <td>Silniční daň</td>
                  <td>
                    250 Kč <small>/auto</small>
                  </td>
                </tr>
                <tr>
                  <td>Srážková daň</td>
                  <td>dle dohody</td>
                </tr>
                <tr>
                  <td>Statistické výkazy</td>
                  <td>dle dohody</td>
                </tr>
                <tr>
                  <td>
                    Zastupování na úřadech{" "}
                    <small>
                      (tzn. kontroly <br className="hidden--landscape" />
                      na FÚ, PSSZ, zdravotních pojišťovnách apod.)
                    </small>
                  </td>
                  <td>
                    400 Kč <small>/hodinu</small>
                  </td>
                </tr>
                <tr>
                  <td>
                    Nahlášení změn ve společnosti{" "}
                    <br className="hidden--landscape" />
                    na Finančním úřadě
                  </td>
                  <td>600 Kč</td>
                </tr>
                <tr>
                  <td>Ostatní služby</td>
                  <td>dle dohody</td>
                </tr>
              </tbody>
            </table>

            <div className="pricelist-note">
              <p>ceny neobsahují 21 % DPH</p>
            </div>

            <div className="btn-wrapper">
              <Link
                title="Zpět na hlavní stránku"
                className="btn btn--ghost"
                to="/"
              >
                <span className="btn__inner">Zpět na hlavní stránku</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Copy />
    </div>
  )
}

export default PriceListPage
